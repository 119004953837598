export default {
    ip:{
        China:'http://47.116.115.210',
        Foreign:'http://47.89.229.58',
        Localhost:'http://localhost',
    },
    home: {
        LinYun: 'LinYun™ BIM Platform',
        Brief1: 'A new starting point for the future of BIM, everyone can use a good model.',
        Brief2: 'Supports the import and automatic integration of commonly used BIM model data, has unprecedented platform leading ' +
            'features that take into account lightweight, effect and information data, follows the development concept of simple,practical and efficient, and is committed to integrating BIM models into daily work.',
        Brief3: '',
        Brief4: 'aims at "everyone can make good use of the model", so that more people can enjoy the real convenience brought by the BIM workflow reform.',
        ReleaseDate: 'Professional Edition release date: October 12, 2024, welcome to download experience!',
        FreeDownload: 'Free Download',
        ExperienceFeedback: 'Experience Feedback',
        UnityInDiversity: 'Unity in Diversity,',
        QualityInDetails: ' Quality in Details.',
        LightWeight: 'Lightweight integration of multi-discipline, multi-format models, ' +
            'consolidating BIM results from various parties, with effortless control over each discipline\'s details',
        SmoothToStart: 'Smooth to Start,',
        SimpleButExceptional: 'Simple but Exceptional.',
        SupportLarge: 'Supports large model loading with minimalist measurement, slicing, viewpoint ' +
            'recording, and attribute viewing, allowing you to smoothly handle reports and presentations with ease.',
        CollaborationAndCommunication: 'Collaboration and Communication',
        SeamlesslyAndEffortlessly: 'Seamlessly and Effortlessly',
        InAnAdvance: 'In an advanced, fast and convenient collaboration mode, record and track issues, enabling efficient multi-party communication ' +
            'and seamless cooperation.',
        NextGeneration: 'Next-Generation',
        BIMCloudPlatform: 'BIM Cloud Platform',
        TheWorld: 'The World\'s First New BIM Platform for the Digital Era.',
        BetweenDifferentSoftware: 'Between different software.',
        SeamlessConnection: 'Seamless connection',
        InstallTheLinYun: 'Install the LinYun plugin to create your own custom workflow.',
        DownloadTheLinYunRevitPlugin: 'Download the LinYunRevit Plugin',
        BuyNow: 'Buy Now',
        ChooseYourPreferredPlan: 'Choose Your Preferred Plan',
        TrialVersion: 'Trial',
        SuitableForBIMModelReporting: 'Suitable for BIM Model Reporting',
        FreeToUse: 'Free to Use',
        TryNow: 'Try Now',
        TrialFeatures: 'Trial Features',
        NumberOfLocalProjects: 'Number of Local Projects (1)',
        MultipleSoftwarePlugins: 'Multiple Software Plugins',
        StorageCapacity: 'Storage Capacity (Limited)',
        ViewpointFunction: 'Viewpoint Function',
        SlicingFunction: 'Slicing Function',
        MeasurementFunction: 'Measurement Function',
        DirectoryTreeFunction: 'Directory Tree Function',
        AttributeFunction: 'Attribute Function',
        ProfessionalVersion: 'Professional',
        SuitableForPersonalProjects: 'Suitable for Personal Projects',
        ClickToUpgrade: 'Click to upgrade',
        ProfessionalFeatures: 'Professional Features',
        NumberOfLocalProjectsProfessional: 'Number of Local Projects (Unlimited)',
        StorageCapacityProfessional: 'Storage Capacity (Unlimited)',
        IssueCollaboration: 'Issue Collaboration (Simplified Mode)',
        ProjectDataMigration: 'Project Data Migration',
        ProfessionalTechnicalSupport: 'Professional Technical Support',
        IncludesAllTrialVersionFeatures: 'Includes All Trial Version Features',
        ProjectVersion: 'Project',
        SuitableForSingleProjectUse: 'Suitable for Single Project Use',
        Year: 'Year',
        Quarter:'Quarter',
        ComingSoon: 'Coming Soon',
        BasicConfiguration: 'Basic Configuration',
        NumberOfCloudProjects: 'Number of Cloud Projects (1)',
        NumberOfAccounts: 'Number of Accounts (5)',
        CloudStorageCapacity: 'Cloud Storage Capacity (2GB)',
        CrossProjectCollaboration: 'Cross-Project Collaboration',
        IssueCollaborationProject: 'Issue Collaboration (Simplified Mode)',
        CloudBasedModel: 'Cloud-Based Model Sharing & Sync',
        CloudCompression: 'Ten times Cloud Compression',
        TechnicalSupport: '24-Hour Technical Support',
        IncludesAllProfessionalVersionFeatures: 'Includes All Professional Version Features',
        EnterpriseVersion: 'Enterprise',
        SuitableForProjectsOfVariousEnterpriseScales: 'Suitable for projects of any size.',
        ContactUs: 'Contact Us',
        ProfessionalConfiguration: 'Professional Configuration',
        WeChatConsultation: 'WeChat Consultation',
        EmailConsultation:'Email Consultation',
        ContactCustomerService:'Contact customer service',
        EmailCopied:'Email has been copied',
        EmailCopiedError:'Replication failed,please manually copy',
        NumberOfCloudProjectsEnterprise: 'Number of Cloud Projects (5)',
        NumberOfAccountsEnterprise: 'Number of Accounts (10)',
        CloudStorageCapacityEnterprise: 'Cloud Storage Capacity (5*2GB)',
        CrossProjectCollaborationEnterprise: 'Cross-Project Collaboration',
        IssueCollaborationEnterprise: 'Issue Collaboration (Professional Mode)',
        CloudBasedModelEnterprise: 'Cloud-Based Model Sharing & Sync',
        CloudCompressionEnterprise: 'Ten times Cloud Compression',
        EnterpriseCustomLOGO: 'Enterprise Custom LOGO',
        SupportsPaidUpgradesForAccountsProjects: 'Supports Paid Upgrades for Accounts & Projects',
        SupportsPaidCustomIntegration: 'Supports Paid Custom Integration',
        TechnicalSupportEnterprise: '24-Hour Technical Support',
        IncludesAllReleasedFeatures: 'Includes All Released Features',
        Expire: 'Login expired, please login again.',
        DynamicRendering: 'Dynamic Rendering',
        BasedOnDynamic: 'Based on dynamic global illumination technology, it supports real-time full dynamic GI without ' +
            'requiring hardware ray tracing. It achieves a balance between detail quality and performance for both indoor and ' +
            'outdoor scenes, delivering high-quality dynamic lighting.',
        PowerfulCompatibility: 'Powerful Compatibility',
        UsingANewly: 'Using a newly developed plugin, it integrates models and information from common BIM and 3D modeling ' +
            'software, supporting multi-user simultaneous uploads, so no more worries about too many complex project models.',
        HighQualityEffects: "High-Quality Effects",
        WithSimpleMaterial: 'With simple material settings in BIM or 3D software, you can import models with one click, achieving' +
            ' rendering effects far beyond existing BIM platforms, making spaces feel more dynamic and delivering a new visual experience.',
        SupportForLargeModels: 'Support for Large Models',
        NewGraphicsRendering: 'New graphics rendering technology and optimization algorithms efficiently process large model data, supporting ' +
            'fast loading, display, and slicing of large models. High-performance computers can handle up to 1 million square meters of large-scale ' +
            'airport projects.',
        ModelLightweight: 'Model lightweight',
        SupportsModelLightweight: 'Supports model lightweighting technology, with self-developed algorithms optimizing models to reduce their size and ' +
            'computational resource consumption, thereby accelerating rendering speed and lowering hardware requirements.',
        SmoothUserExperience: 'Smooth user experience',
        WithAMinimalist: 'With a minimalist software interface, users can quickly get started with classic and intuitive operations ' +
            'within minutes, making software learning no longer a burden.',
        InformationModel: 'Information model',
        SupportsTheIntegrationOf: 'Supports the integration of model component attributes and parameter information, enabling ' +
            'rapid access via the cloud platform, helping you better utilize the model.',
        FlexibleIntegration: 'Flexible integration',
        IndependentlyDeveloped: 'Independently developed by a domestic team, it is more suited to the market demands of ' +
            'domestic BIM teams and can be flexibly integrated into third-party platforms in the future.',
        img: 'english.png',
        img1:'linYun1_E.png',
        img2:'linYun2_E.png',
        img3:'linYun3_E.png',
        img4:'linYun4_E.png',
        AlreadyProfessional:'It\'s already professional version',
        PleaseLogInFirst:'Please log in first'

    },
    top: {
        Product: 'Product',
        Workflow: 'Workflow',
        Tutorials: 'Tutorials',
        Pricing: 'Pricing',
        Download: 'Download',
        Login: 'Login',
        Register:'Register',
        AccountLogin: 'AccountLogin',
        Honorific: 'Honorific',
        Username: 'Username:',
        Password: 'Password:',
        Affirm: 'Affirm',
        Cancel: 'Cancel',
        Logout: 'Logout',
        GoBackstage: 'GoBackstage',
        LoginSuccessful: 'Login Successful',
        AccountPasswordIsIncorrect:'Login failed, the account password is incorrect',
        LogoutSuccessful: 'Logout Successfully',
        ExitReminder: 'Exit reminder',
        ExitReminderText: 'Are you sure you want to log out?',
        CancelLogout: 'CancelLogout',
        Expire: 'Login expired, please login again.',
        LinYun:'QiLin BIM',
        AccountExpired:'Login failed, account expired',
    },
    download: {
        Title: 'The LinYun Platform Installation Package and Plugins',
        NecessaryPlugIns: 'Necessary plug-ins',
        DownloadLinYunRevitPlugin: 'Download LinYun-Revit plugin',
        DownloadSketchUpPlugin: 'Download SketchUp plugin',
        DownloadRhinoPlugin: 'Download Rhino plugin',
        VersionHistory: 'Version history',
    },
    download320:{
        DownloadV320:'Download V3.2.1',
        LatestVersion:'Latest version',
        UpdateContent: 'Update content',
        November82024:'November 15, 2024',
        PlatformClient:'Platform client',
        AddedMaterialReplacement:'1.Added material replacement and material library feature (Beta).',
        AddedPDFDrawing:'2.Added PDF drawing review feature (Beta).',
        ComprehensiveUpgrades:'3.Comprehensive upgrades to shaded model viewing for better visual quality.',
        AdjustedThe:'4.Adjusted the high-realism display technology (Beta).',
        ImprovedStability:'5.Improved stability for model data uploads.',
        EnhancedData:'6.Enhanced data synchronization stability for advanced issue collaboration.',
        UpgradedProject:'7.Upgraded project data version (previously imported projects are no longer compatible).',
        OptimizedUI:'8.Optimized UI display size for the measurement tool.',
        OtherUI:'9.Other UI optimizations and bug fixes.',
    },
    download300:{
        DownloadV300:'Download V3.0.3',
        LatestVersion:'Latest version',
        UpdateContent: 'Update content',
        October122024:'October 12, 2024',
        PlatformClient:'Platform client',
        NewRealistic:'1.New realistic dynamic environmental sky (needs to be enabled in settings);',
        QuickRendering:'2.Quick rendering of Revit materials for realistic material effects;',
        Significant:'3.Significant improvement in model mesh loading speed;',
        Optimization:'4.Optimization of model data lightweight algorithm, further enhancing compression efficiency;',
        MultiEnd:'5.Multi-end viewpoint synchronization function fixed;',
        QuickUpload:'6."Quick Upload" and "Quick View" modules officially launched in 3D mode;',
        FilterDirectory:'7.Filter directory function for issues officially launched in advanced collaboration mode;',
        RevitModel:'8.Revit model materials can now be exported in shaded or realistic mode;',
        NewLighting:'9.New lighting time adjustment feature;',
        EditingAndUpdating:'10.Editing and updating function for "issue markers" in advanced collaboration mode;',
        NewNetwork:'11.New network status indicator display (gray, yellow, green);',
        OptimizedUI:'12.Optimized UI design for "professional" tag in advanced collaboration mode;',
        Enhanced:'13.Enhanced rendering display effects for "normal" level;',
        Fixed:'14.Fixed occasional glass rendering anomalies in specific situations;',
        OtherUI:'15.Other UI optimizations and known bug fixes.',
    },
    download252: {
        DownloadV252Preview: 'Download V2.5.2_Preview',
        StableVersion:'Stable Version',
        UpdateContent: 'Update content',
        August92024: 'August 9, 2024',
        PlatformClient: 'Platform client',
        SimpleAndQuick: '1.Simple and quick question and question list function online;',
        OptimizeLarge: '2.Optimize large model data support (project data generated by',
        NeedTo: 'previous versions cannot be reused Need to re-import);',
        RectangleBox: '3.3D rectangle box and text function;',
        LocalIssue: '4.Local issue export and import function;',
        CustomizeTheReduction: '5.Customize the reduction when importing;',
        ProblemFilterPreset: '6.Problem filter preset test function;',
        ModelSubfileUninstall: '7.Model subfile uninstall, delete the test function;',
        OtherUI: '8.Other UI optimizations and known BUG fixes;',
    },
    download212:{
        DownloadV212Preview: 'Download V2.1.2_Preview',
        StableVersion:'Stable Version',
        UpdateContent: 'Update content',
        July12024: 'July 1, 2024',
        PlatformClient: 'Platform client',
        UIOptimization:'1.UI optimization and adjustment of problem collaboration interface.',
        EachAccountCan:'2.Each account can only be used to log in to a single device online at the same time. Support account withdrawal Line',
        WithdrawalLine:"working mode; Users from Europe and the United States can register and log in.",
        TheModelUpdate:"3.The model update mechanism is improved when the project is opened.",
        FixedACrash:'4.Fixed a crash issue when importing a large model.',
        OptimizeTheLocal:'5.Optimize the local file storage mode.',
        OtherBUGFixes:'6.Other BUG fixes and stability adjustments.',
    },
    download200:{
        DownloadV200Preview: 'Download V2.0.0_Preview',
        StableVersion:'Stable Version',
        UpdateContent: 'Update content',
        June72024:'June 7, 2024',
        PlatformClient: 'Platform client',
        AddedANew:'1.Added a new Logo icon to more scenes.',
        UseANewUi:'2.Use a new ui.',
        DesignANewRevitPlugin:'3. Design a new revit plugin.',
        AddedTheCloudProject:'4. Added the Cloud project.',
        AddedSharingFunction:'5.Added sharing function.',
        AddedTheSynchronizationFunction:'6.Added the synchronization function.',
        AddedIssueCollaboration:'7.Added issue collaboration.',
        OptimizeTheImport:'8.Optimize the import efficiency of DS files.',
        OptimizeTheResourceUsage:'9.Optimize the resource usage when importing DS files.',
        AddFunctionsSuchAsHiding:'10.Add functions such as hiding, isolation, and transparency to the right-click menu in the scene.',
        AddedTheHiding:'11.Added the hiding and isolation functions in the directory tree.',
        AdjustedTheFunction:'12.Adjusted the function of deleting local project and cloud project.',
        WebPageSide:'Web page side',
        AddAccountRegistrationFunction:'1.Add account registration function.',
        PublishAPrice:'2.Publish a price selection page.',
    },
    download110:{
        DownloadV110Beta: 'Download V1.1.0_beta',
        StableVersion:'Stable Version',
        UpdateContent: 'Update content',
        April102024:'April 10, 2024',
        PlatformClient: 'Platform client',
        UseTheNewLogoIcon:'1.Use the new Logo icon.',
        AddTheDirectory:'2.Add the directory tree subfunction.',
        ModifyTheProperty:'3.Modify the property data query mode and optimize performance.',
        OptimizeTheView:'4.Optimize the view jump mode and speed.',
        OptimizeUI:'5.Optimize UI and interactive experience.',
        ModifySomeBugs:'6.Modify some bugs.',
    },
    download101:{
        DownloadV101Beta: 'Download V1.0.1_beta',
        StableVersion:'Stable Version',
        UpdateContent: 'Update content',
        January312024:'January 31, 2024',
        PlatformClient: 'Platform client',
        UpdateTheInstallation:'1.Update the installation path.',
        LanguageSelection:'2.Language selection (Chinese and English)',
        CloseSseLink:'3.Close sse link (close program crash bug fix).',
        ImportFileLog:'4.Import file log (to prevent disk c memory explosion).',
        AdjustTheUiSettings:'5. Adjust the ui Settings.',
        BUGRepair:'6.BUG repair and stability adjustment.',
    },
    download100:{
        DownloadV100Beta: 'Download V1.0.0_beta',
        StableVersion:'Stable Version',
        UpdateContent: 'Update content',
        January62024:'January 6, 2024',
        ThePublicBeta:'The public beta version has been officially released.',
    }
};